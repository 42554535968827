import React from 'react';
import Divider from '../../components/Divider';
import fundoElasImg from '../../assets/images/logo-elas-2020.svg';
import fundoFridaImg from '../../assets/images/frida-logo.svg';
import './sponsor.css';

const Sponsorship: React.FC = () => {
    return (
        <div id="page-sponsorship">
            <Divider text="APOIADORES"/>
            <div className="container h-100">
                <div className="row mb-5">
                  <div className="col-md-12">
                    <h3 className="h3-header" id="sponsor-header">"Para nós é essencial uma abordagem que considere não somente a violência de gênero e sexual, mas, também, a discriminação racial e a violência institucional."</h3>
                  </div>
                </div>
                <div className="row p-3 mb-5">
                    <div className="d-flex flex-md-row flex-column sponsor">
                        <div className="align-self-center p-3 mr-1">
                            <img id="img-sponsor" src={fundoFridaImg} />    
                        </div>
                        
                        <div className="align-self-center p-3">    
                            <p >O Fundo Frida é um recurso internacional que está localizado em várias partes do mundo, a organização  apoia a Coletiva Teia desde o ano de 2016 . O Frida apoia jovens líderes com recursos e amplifica as nossas vozes dando atenção aos nossos trabalhos e apoio, adotando importantes valores que  incluem Flexibilidade, Recursos, Inclusividade, Diversidade e Ação. </p>
                            <p className="h1"><strong>Para saber mais sobre o fundo acesse o site:</strong> <a href="https://youngfeministfund.org/">Fundo Frida</a></p>
                        </div>
                    </div>
                </div>
                <div className="row p-3 mb-5">  
                    <div className="d-flex flex-sm-row flex-column">
                        <div className="align-self-center p-3 mr-1">
                            <img id="img-sponsor-elas" src={fundoElasImg} />
                        </div>
                        <div className="align-self-center p-3">    
                            <p> O Fundo Elas é o único fundo independente dedicado às mulheres existentes no Brasil. O Fundo busca promover o protagonismo de mulheres mobilizando e investindo recursos em suas iniciativas. A Coletiva Teia foi apoiada pelo Fundo por meio do Edital Building Movements- Mulheres em Movimento no ano de 2019. </p>
                            <p className="h1"><strong>Para saber mais sobre o fundo acesse o site:</strong> <a href=" http://www.fundosocialelas.org/">Fundo Elas</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sponsorship;